 export const skillsData = [
    'Java',
    'Springboot',
    'Angular',
    'Javascript',
    'Typescript',
    'Docker',
    'GIT',
    'Bootstrap',
    'MySQL',
    'React',
    'Spring',
]
