export const achievementData = {
    bio : "A thinker sees his own actions as experiments and questions--as attempts to find out something. Success and failure are for him answers above all.",
    achievements : [
        {
            id : 1,
            title : 'Best Project Intern Award',
            details : 'I have recieved the best intern award during my internship from Wipro Technologies, Greater Noida. with PRP Score 82.27 %',
            date : 'May 11, 2016',
            field : 'Intern',
            image : '',
        },
        {
            id : 2,
            title : 'Professional Scrum Master-I',
            details : 'The Professional Scrum Master™ I (PSM I) certification validates your knowledge of the Scrum framework, the Scrum Master accountabilities and how to apply Scrum.',
            date : 'Sept 6, 2019',
            field : 'Agile',
            image : 'https://scrumorg-website-prod.s3.amazonaws.com/drupal/inline-images/2022-09/asset_44psmi_0.png',
        }
    ]
}