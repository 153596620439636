/* eslint-disable */
import { 
    bwThemeLight, bwThemeDark, blueThemeLight, blueThemeDark
} from '../theme/theme'


export const themeData = {
    theme: blueThemeDark
}


// Choose theme from above