import { about,education,experience} from './images'


export const blueThemeDark = {
    type: 'dark',
    primary: 'white',
    primary400: '#6e76c7',
    primary600: '#3644c9',
    primary80: '#545fc4cc',
    primary50: '#545fc480',
    primary30: '#545fc44d',
    secondary: '#01122f',
    secondary70: '#212121b3',
    secondary50: '#21212180',
    tertiary: '#eaeaea',
    tertiary80: '#eaeaeacc',
    tertiary70: '#eaeaeab3',
    tertiary50: '#eaeaea80',
    aboutimg1: about,
    aboutimg2: about,
    eduimg: education,
    expimg: experience,
}


