export const educationData = [
    {
        id: 1,
        institution: 'Aligarh Muslim University',
        course: 'Master in Computer Sciene & Applications (MCA)',
        startYear: '2013',
        endYear: '2016'
    },
    {
        id: 2,
        institution: 'Aligarh Muslim University',
        course: 'Bachelor of Sciene (BSc)',
        startYear: '2010',
        endYear: '2013'
    },
    {
        id: 3,
        institution: 'West Bengal Higher Secondery Board',
        course: '12th',
        startYear: '2010',
        endYear: '2008'
    },
    {
        id: 4,
        institution: 'West Bengal Secondery Board',
        course: '10th',
        startYear: '2008',
        endYear: '2009'
    }
]