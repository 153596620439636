import docker from '../assets/svg/skills/docker.svg'
import angular from '../assets/svg/skills/angular.svg'
import javascript from '../assets/svg/skills/javascript.svg'
import react from '../assets/svg/skills/react.svg'
import typescript from '../assets/svg/skills/typescript.svg'
import bootstrap from '../assets/svg/skills/bootstrap.svg'
import mysql from '../assets/svg/skills/mysql.svg'
import java from '../assets/svg/skills/java.svg'
import git from '../assets/svg/skills/git.svg'
import spring from '../assets/svg/skills/spring.svg'
import springboot from '../assets/svg/skills/springboot.png'


export const skillsImage = (skill) => {
    const skillID = skill.toLowerCase();
    switch (skillID) {
        
        case 'angular':
            return angular;
        case 'docker':
            return docker;
        case 'typescript':
            return typescript;
        case 'javascript':
            return javascript;
        case 'react':
            return react;
        case 'bootstrap':
            return bootstrap;
        case 'mysql':
            return mysql;
        case 'java':
            return java;        
        case 'git':
            return git;
        case 'spring':
            return spring;
        case 'springboot':
            return springboot;    
        default:
            break;
    }
}
